kbd {
  border: 2px solid #ccc;
  box-shadow: 2px 2px #999;
  display: inline-block;
  padding: 0 5px;
  border-radius: 0.25em;
  min-width: 1.5em;
  text-align: center;
  margin-right: .15em;
}
kbd:hover {
  position: relative;
  top: 2px;
  left: 2px;
}
